<template>
  <b-form @submit.prevent="save">
    <vue-element-loading :active="loader" />
    <b-form-group label="Contact Name *">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <feather-icon icon="UserIcon" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input v-model="payload.contactName"></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group label="Telegram User">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <feather-icon icon="InfoIcon" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input v-model="payload.telegramUser"></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group label="Whatsapp">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <feather-icon icon="PhoneIcon" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input v-model="payload.whatsapp"></b-form-input>
      </b-input-group>
    </b-form-group>

    <hr />

    <div class="d-flex">
      <submit-button class="ml-auto" @click.stop="save"></submit-button>
      <cancel-button class="ml-1" @onClick="closeModal"></cancel-button>
    </div>
  </b-form>
</template>

<script>
import api from "@api";

export default {
  name: `ClientDetail`,
  props: {
    agentCode: {
      type: String,
      required: true,
    },
    client: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      payload: {
        contactName: null,
        telegramUser: null,
        whatsapp: null,
      },
      loader: false,
    };
  },
  async mounted() {
    await this.loadClient();
  },
  methods: {
    async loadClient() {
      if (this.client) {
        let result = await api.get(`client/${this.client.code}`);
        this.payload = result;
      }
    },
    async save() {
      this.loader = true;
      try {
        let result;
        this.payload.agentCode = this.agentCode;
        if (this.client) {
          result = await api.put(`client/${this.client.code}`, this.payload);
        } else {
          result = await api.post("client", this.payload);
        }
        this.$emit("onSaveSuccess", result);
        this.clearObject(this.payload);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    closeModal() {
      this.$emit("onClose");
    },
  },
};
</script>
